<template>
  <div v-if="spinnerShow" class="spinner_main">
    <span class="spinner_square">
      <div class="spinner"></div>
    </span>
  </div>
</template>

<script>
export default {
  props: ["spinnerShow"],
  name: "spinner",
  data() {
    return {};
  },
};
</script>