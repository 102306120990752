// user
export const setUserProfile = 'setUserProfile'
export const setUserData = 'setUserData'
export const setIdToken = 'setIdToken'
export const setUserReceive = 'setUserReceive'
export const setUserPaid = 'setUserPaid'
export const setAddFriendToken = 'setAddFriendToken'
// end user

// group
export const setGroupING = 'setGroupING'
export const setNowGroupID = 'setNowGroupID'
export const setNowGroup = 'setNowGroup'
export const setGroupMember = 'setGroupMember'
export const setAddMemberToken = 'setAddMemberToken'
export const setGroupBalance = 'setGroupBalance'
// end group


// item in group
export const setNowItemID = 'setNowItemID'
export const setNowItem = 'setNowItem'
//end item in group

// addgroup
export const setNewGroupID = 'setNewGroupID'
export const setNewGroup = 'setNewGroup'
//end addgroup