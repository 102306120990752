<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="active">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'Group_balance', params: { id: this.groupID } }"
      >
        <div class="">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ nowItem.item_name }}</div>
      <div class="flex">
        <span>{{ nowItem.price }}</span>
      </div>
    </div>

    <div class="finGroupContent_2">
      <div class="groupList_editMember xcenter" style="border: none">
        <div class="groupList_2">
          <div class="Title">分擔成員：</div>
          <div
            class="memberImg"
            v-for="(item, index) in itemMember"
            :key="index"
            @click="removeFromItem(item, index)"
          >
            <img class="circleimg" :src="item.image_url" alt="" />
            <span class="">{{ item.display_name }}</span>
            <span class="money textgrey4"
              ><img src="/images/cross.svg" alt=""
            /></span>
          </div>
        </div>

        <div class="groupList_2">
          <div class="Title">其他成員：</div>
          <p v-if="allmember">所有群組成員都已在此項目中</p>
          <div
            class="memberImg"
            v-for="(item, index) in differentMember"
            :key="index"
            @click="addToItem(item, index)"
          >
            <img class="circleimg" :src="item.image_url" alt="" />
            <span class="textgrey4">{{ item.display_name }}</span>
            <span class="money"><img src="/images/plus.svg" alt="" /></span>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="finBtnList">
      <button class="activeBtn" @click="modifyMember">確認</button>
      <button class="cancel">取消</button>
    </div> -->
    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_sure="btnList_sure"
      @sureAct="modifyMember"
      @sureCancel="cancel"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "edit_itemMember",
  data() {
    return {
      spinnerShow: false,
      trueView: true,
      btnList_sure: ["確認", "取消"],
      deleteView: false,
      itemID: this.$route.params.item_id,
      allmember: false,
      itemMember: [],
      differentMember: [],
      itemMemberID: [],
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getNowItem();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      nowGroup: (state) => state.group.nowGroup,
      groupID: (state) => state.group.nowGroupID,
      groupMember: (state) => state.group.groupMember,
      nowItem: (state) => state.group.nowItem,
    }),
  },
  methods: {
    getNowItem() {
      this.$store
        .dispatch("group/getNowItem")
        .then(() => {
          this.getItemMember();
          this.memberFilter();
        })
        .catch((error) => {
          alert(error);
        })
    },
    getItemMember() {
      this.itemMember = this.nowItem.shared_by;
    },
    memberFilter: function () {
      if (this.groupMember.length == this.itemMember.length) {
        this.allmember = true;
      } else {
        this.allmember = false;
        let repeatmember = [];

        this.groupMember.forEach((value) => {
          this.itemMember.forEach((el) => {
            if (value.member_id == el._id) {
              repeatmember.push(value);
            }
          });
        });

        this.differentMember = this.groupMember.filter(
          (e) => !repeatmember.includes(e)
        );
        console.log(this.differentMember);
      }

      this.spinnerShow = false;
    },
    addToItem: function (item, index) {
      this.differentMember.splice(index, 1);
      this.itemMember.push(item);
    },
    removeFromItem: function (item, index) {
      this.itemMember.splice(index, 1);
      this.differentMember.push(item);
    },
    modifyMember: function () {
      this.itemMember.forEach((value) => {
        if (value._id) {
          this.itemMemberID.push(value._id);
        } else if (value.member_id) {
          this.itemMemberID.push(value.member_id);
        }
      });
      console.log(this.itemMemberID);

      this.$store
        .dispatch("group/editItem", {
          itemName: this.nowItem.item_name,
          itemMemberID: this.itemMemberID,
          itemPrice: Number(this.nowItem.price),
        })
        .then((response) => {
          console.log("editItem", response);
          this.$router.push({
            name: "Group_edititem",
            params: { item_id: this.nowItem.item_id },
          });
        })
        .catch((error) => {
          alert(error);
        });
    },
    cancel() {
      this.$router.push({
        name: "Group_edititem",
        params: { item_id: this.nowItem.item_id },
      });
    },
  },
};
</script>