<template>
  <div class="mainFrame">
    <div class="profilelist">
      <div class="flex">
        <img
          class="circleimg"
          :src="userprofile.image_url"
          alt=""
          style="width: 50px; height: 50px"
        />
        <span class="trashcan textgrey2">{{userprofile.display_name}}</span>
      </div>
    </div>

    <div class="status" style="margin-bottom: 30px">
      <router-link to="/Profile">
        <div class="">
          好友
          <span></span>
        </div>
      </router-link>

      <router-link to="/Profile_setting">
        <div class="active">
          設定
          <span></span>
        </div>
      </router-link>
    </div>

      <div class="finList_profile flex">
        <span class="Title textgrey2">面板</span>
        <span class="trashcan hightLight"> 深色模式 </span>
      </div>

      <div class="finList_profile flex" style="border:none">
        <span class="Title textgrey2">語言</span>
        <span class="trashcan hightLight"> 繁體中文 </span>
      </div>

      <div class="finList_profile flex">
        <span class="Title textgrey2">單位</span>
        <span class="trashcan hightLight"> 新台幣 </span>
      </div>

      <div class="finList_profile flex" style="border:none">
        <span class="Title textgrey2">版本</span>
        <span class="trashcan hightLight"> 1.1.1 </span>
      </div>

      <div class="finList_profile flex">
        <span class="Title textgrey2">給團隊一點建議吧！</span>
        <span class="trashcan"
          ><i class="fas fa-chevron-right hightLight"></i
        ></span>
      </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "profile_setting",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userprofile: (state) => state.user.userProfile,
    }),
  },
};
</script>