import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import group from "./group";
import add from "./add";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    group,
    add,
  },
});
