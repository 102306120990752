<template>
  <div class="footer">
    <div class="footerRow">
      <div class="footer_img">
        <router-link to="/">
          <i
            class="fas fa-home allcenter"
            style="color: #f35e5e; font-size: 1rem"
          ></i>
        </router-link>
      </div>
      <div class="footer_img">
        <router-link to="/Add_group">
          <i
            class="fas fa-plus-circle allcenter"
            style="color: #f35e5e; font-size: 36px"
          ></i>
        </router-link>
      </div>

      <div class="footer_img">
        <router-link to="/Activity_receive">
          <i
            class="far fa-bell allcenter"
            style="color: #464545; font-size: 1rem"
          >
          </i>
        </router-link>
      </div>

      <div class="footer_img">
        <router-link to="/profile">
          <img
            class="circleimg allcenter"
            style="width:20px; height:20px;"
            :src="userprofile.image_url"
            alt=""
          />
        </router-link>
      </div>
    </div>
    <div class="footerLine"></div>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userprofile: (state) => state.user.userProfile,
    }),
  },
};
</script>