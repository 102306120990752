import axios from "../axios/index";
import * as types from "./mutationType";

const state = {
  userProfile: {},
  userData: {},
  userIdToken: "",
  userReceive: [],
  userPaid: [],
  addFriendToken: "",
};

const mutations = {
  [types.setUserProfile](state, items) {
    state.userProfile = items;
  },
  [types.setUserData](state, items) {
    state.userData = items;
  },
  [types.setIdToken](state, items) {
    state.userIdToken = items;
  },
  [types.setUserReceive](state, items) {
    state.userReceive = items;
  },
  [types.setUserPaid](state, items) {
    state.userPaid = items;
  },
  [types.setAddFriendToken](state, items) {
    state.addFriendToken = items;
  },
};

const actions = {
  async sendToken(context) {
    try {
      let response = await axios.get("/user/auth", {
        headers: { id_token: state.userIdToken },
      });
      window.localStorage.setItem("access_token", response.data.access_token);
      context.commit(types.setUserData, response.data);
      return response;
    } catch (err) {
      window.localStorage.clear();
      location.reload();
      return Promise.reject(err);
    }
  },
  async getProfile(context) {
    try {
      let response = await axios.get("/user");
      context.commit(types.setUserProfile, response.data);
      console.log("userProfile", response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  setIDToken(context, data) {
    context.commit(types.setIdToken, data);
  },
  async getReceive(context) {
    try {
      let response = await axios.get("/user/receive");
      context.commit(types.setUserReceive, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getPaid(context) {
    try {
      let response = await axios.get("/user/paid");
      context.commit(types.setUserPaid, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getAddFriendToken(context) {
    try {
      let response = await axios({
        method: "post",
        url: `/user/friends/invite`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      context.commit(types.setAddFriendToken, response.data.invite_token);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteFriend(context, data) {
    try {
      let response = await axios({
        method: "delete",
        url: `/user/friends/${data.friend_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
