<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="active">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'Group_balance', params: { id: this.groupID } }"
      >
        <div class="">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ nowGroup.group_name }}</div>
      <div class="flex">
        <span>{{ nowGroup.created_at | moment("YYYY.MM.DD") }}</span>
      </div>
    </div>

    <div class="group_itemlist_project xcenter" style="border: none">
      <div class="flex">
        <span>項目</span>
      </div>

      <div
        class="eachProject xcenter"
        v-for="(item, index) in nowGroup.items"
        :key="index"
        @click="chooseItem(item, index)"
      >
        <span :class="{ hightLight: nowpickIndex == index }">{{
          item.item_name
        }}</span>
        <span :class="[{ hightLight: nowpickIndex == index }, 'money']"
          >-{{ item.spend }}</span
        >
      </div>
    </div>

    <!-- <div class="finBtnList" v-if="!deleteView">
      <button class="activeBtn" @click="alertChoose">編輯項目</button>
      <button class="cancel" @click="cancel">取消</button>
    </div>

    <div v-if="deleteView" class="deleteView"></div>
    <div v-if="deleteView" class="deleteBtnList">
        <button class="deleteBtn" @click="goEditItem">編輯</button>
      <button class="cancel" @click="cancel">取消</button>
    </div> -->

    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_delete="btnList_delete"
      @deleteAct="goEditItem"
      @deleteCancel="cancel"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Group_itemlist",
  data() {
    return {
      spinnerShow:false,
      trueView: false,
      btnList_delete: ["編輯", "取消"],
      deleteView: false,
      nowItemID: "",
      nowpickIndex: null,
    };
  },
  mounted() {
    this.spinnerShow = true;
    // 重抓目前群組是因為編輯完項目會回到這頁
    this.getNowGroup();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      nowGroup: (state) => state.group.nowGroup,
      groupID: (state) => state.group.nowGroupID,
    }),
  },
  methods: {
     getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(()=>{
          this.spinnerShow = false;
        })
    },
    chooseItem: function (item, index) {
      this.nowItemID = item._id;
      this.nowpickIndex = index;
      this.deleteView = true;
    },
    cancel: function () {
      this.nowpickIndex = null;
      this.deleteView = false;
    },
    goEditItem() {
      // 同時存取itemID進localstorage裡
      window.localStorage.setItem('nowItemID',this.nowItemID);

      this.$router.push({
        name: "Group_edititem",
        params: { id: this.groupID, item_id: this.nowItemID },
      });
    },
  },
};
</script>