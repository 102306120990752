<template>
  <div class="mainFrame">
    <div class="profilelist">
      <div class="flex">
        <img
          class="circleimg"
          :src="userprofile.image_url"
          alt=""
          style="width: 50px; height: 50px"
        />
        <span class="trashcan textgrey2">{{ userprofile.display_name }}</span>
      </div>
    </div>

    <div class="status" style="margin-bottom: 30px">
      <router-link to="/Profile">
        <div class="active">
          好友
          <span></span>
        </div>
      </router-link>

      <router-link to="/Profile_setting">
        <div class="">
          設定
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="finList_profile flex">
      <span class="Title">好友邀請</span>
      <span class="trashcan"
        ><i class="fas fa-chevron-right hightLight"></i
      ></span>
    </div>

    <div class="Memberdiv memberdiv_profile xcenter">
      <div class="memberImgDiv paddtop12">
        <div class="memberImg" @click="addFriend">
          <img
            class="circleimg"
            src="/images/add.png"
            alt=""
            style="border: 1px solid #d5d4d4"
          />
          <span>新增好友</span>
        </div>
        <div
          class="memberImg"
          v-for="(item, index) in userprofile.friend_list"
          :key="index"
          @click="chooseFriend(item, index)"
        >
          <img
            :class="[{ hightLightImg: nowpickIndex == index }, 'circleimg']"
            :src="item.image_url"
            alt=""
          />
          <span :class="{ hightLight: nowpickIndex == index }">{{
            item.display_name
          }}</span>
        </div>
      </div>
    </div>

    <!-- 按鈕組件化 -->
    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_delete="btnList_delete"
      @deleteCancel="cancel"
      @deleteAct="deleteFriend"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "profile",
  data() {
    return {
      spinnerShow: false,
      btnList_delete: ["刪除", "取消"],
      trueView: false,
      deleteView: false,
      nowpickFriend: {},
      nowpickIndex: null,
    };
  },
  mounted() {
    this.spinnerShow = true;
    console.log(this.userprofile);
    this.getAddFriendToken();
  },
  computed: {
    ...mapState({
      userprofile: (state) => state.user.userProfile,
      addFriendToken: (state) => state.user.addFriendToken,
    }),
  },
  methods: {
    getAddFriendToken() {
      this.$store
        .dispatch("user/getAddFriendToken")
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    addFriend: function () {
      console.log(window.liff.isApiAvailable("shareTargetPicker"));

      window.liff
        .shareTargetPicker([
          {
            type: "flex",
            altText: "Flex Message",
            contents: {
              type: "bubble",
              body: {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: `${window.config.redirectUri}/logo.png`,
                    size: "full",
                    aspectMode: "cover",
                    aspectRatio: "2:3",
                    gravity: "top",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "text",
                            text: `成為${this.userprofile.display_name}的Fintake好友吧！`,
                            size: "xl",
                            color: "#ffffff",
                            weight: "bold",
                          },
                        ],
                      },
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "filler",
                          },
                          {
                            type: "box",
                            action: {
                              type: "uri",
                              uri: `${window.config.redirectUri}/#/addfriend?token=${this.addFriendToken}`,
                            },
                            layout: "baseline",
                            contents: [
                              {
                                type: "filler",
                              },
                              {
                                type: "icon",
                                url: "https://cdn1.iconfinder.com/data/icons/security-law/500/law_iconsArtboard_1_copy_3-256.png",
                              },
                              {
                                type: "text",
                                text: "加好友",
                                color: "#ffffff",
                                flex: 0,
                                offsetTop: "-2px",
                              },
                              {
                                type: "filler",
                              },
                            ],
                            spacing: "sm",
                          },
                          {
                            type: "filler",
                          },
                        ],
                        borderWidth: "1px",
                        cornerRadius: "4px",
                        spacing: "sm",
                        borderColor: "#ffffff",
                        margin: "xxl",
                        height: "40px",
                      },
                    ],
                    position: "absolute",
                    offsetBottom: "0px",
                    offsetStart: "0px",
                    offsetEnd: "0px",
                    backgroundColor: "#03303Acc",
                    paddingAll: "20px",
                    paddingTop: "18px",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "Become My Fintake Friend !",
                        color: "#ffffff",
                        align: "center",
                        size: "xs",
                        offsetTop: "3px",
                      },
                    ],
                    position: "absolute",
                    cornerRadius: "20px",
                    offsetTop: "18px",
                    backgroundColor: "#F35E5E",
                    offsetStart: "20%",
                    height: "25px",
                    width: "60%",
                  },
                ],
                paddingAll: "0px",
              },
            },
          },
        ])
        .then()
        .catch(function (error) {
          console.log(error);
          alert("發送失敗！");
        });
    },
    chooseFriend(item, index) {
      this.deleteView = !this.deleteView;
      this.nowpickFriend = item;
      this.nowpickIndex = index;
      console.log(this.nowpickFriend);
    },
    cancel() {
      this.deleteView = false;
      this.nowpickIndex = null;
    },
    deleteFriend() {
      this.$store
        .dispatch("user/deleteFriend", {
          friend_id: this.nowpickFriend._id,
        })
        .then((response) => {
          console.log("deleteFriend", response);
          this.$router.push({
            name: "Profile",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>