<template>
  <div class="mainFrame"></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "addfriend",
  data() {
      return{
        IDtoken:"",
        addFriendtoken: this.$router.app._route.query.token,
      }
  },
  beforeCreate() {
    window.liff
      .init({
        liffId: window.config.liffId,
      })
      .then(() => {
        if (!window.liff.isLoggedIn()) {
          this.logIn();
        } else {
          this.login = true;
          this.getToken();
        }
      });
  },
  computed: {
    ...mapState({
      userprofile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    logIn: function () {
      window.liff.login({
        redirectUri: `${window.config.redirectUri}/addfriend`,
      });
    },
    getToken() {
      this.IDtoken = window.liff.getIDToken();
      // 把ID_token存進store
      this.$store.dispatch("user/setIDToken", this.IDtoken);
      this.sendToken();
    },
    sendToken() {
      this.$store
        .dispatch("user/sendToken")
        .then((response) => {
          console.log(response);
          console.log(localStorage);
          this.getProfile();
          this.addInFriend();
        })
        .catch((err) => {
          alert(err);
          // window.localStorage.clear();
          // window.liff.logout();
        });
    },
    getProfile() {
      this.$store.dispatch("user/getProfile");
    },
    addInFriend: function () {
      this.$axios({
        method: "get",
        url: `/user/friends/invite`,
        params: {
          token: this.addFriendtoken,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("addInFriend", res);
          this.$router.push({ name: "Profile" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>