<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Group_balance' }">
        <div class="active">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ nowGroup.group_name }}</div>
      <div class="flex">
        <span>{{ nowGroup.created_at | moment("YYYY.MM.DD") }}</span>
      </div>
    </div>

    <div class="finGroupContent_2">
      <div class="groupList xcenter" style="border: none">
        <div class="groupList_2">
          <div class="Title textgrey2 text18">剩餘款項</div>
          <div class="flex">
            <img class="circleimg" :src="payer.image_url" alt="" />
            <span class="myname">{{ payer.dispatch }}</span>
            <span class="money">{{ payer.payment }}</span>
          </div>
        </div>

        <div class="groupList_2">
          <div class="Title">輸入金額：</div>
          <div class="groupContent flex">
            <input
              type="text"
              class="inputTheme helfdiv"
              placeholder="請輸入金額"
              v-model="payer_payment"
            />
            <span class="hightLight text14 righticon"> 付款餘額 </span>
          </div>
        </div>

        <div class="Title textgrey2 text18">付款紀錄</div>
        <div
          class="groupList_2 flex"
          v-for="(item, index) in payment_history"
          :key="index"
          @click="choosePayment(item, index)"
        >
          <div class="Title_payment">
            <span :class="[{ hightLight: nowpickIndex == index }, 'textgrey3']">
              {{ item.paid_at | moment("YYYY.MM.DD") }}
            </span>
            <span
              :class="[
                { hightLight: nowpickIndex == index },
                'money',
                'textgrey3',
              ]"
            >
              {{ item.payment }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_sure="btnList_sure"
      :btnList_delete="btnList_delete"
      @sureAct="updatePayment"
      @sureCancel="sureCancel"
      @deleteAct="deletePayment"
      @deleteCancel="deleteCancel"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Group_payment",
  data() {
    return {
      spinnerShow: false,
      trueView: true,
      btnList_sure: ["確認", "取消"],
      btnList_delete: ["刪除", "取消"],
      deleteView: false,
      payerID: this.$route.params.payerID,
      payer: {},
      payer_payment: "",
      nowpickIndex: null,
      nowpickPayment: {},
      payment_history: [],
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getNowGroup();
    this.getPayment();
  },
  computed: {
    ...mapState({
      nowGroup: (state) => state.group.nowGroup,
      groupID: (state) => state.group.nowGroupID,
    }),
  },
  methods: {
    getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(()=>{
        })
    },
    choosePayment(item, index) {
      this.deleteView = !this.deleteView;
      this.nowpickPayment = item;
      this.nowpickIndex = index;
    },
    sureCancel() {
      this.payer_payment = "";
    },
    deleteCancel() {
      this.deleteView = false;
      this.nowpickIndex = null;
    },
    getPayment() {
      this.$store
        .dispatch("group/getPayment", this.payerID)
        .then((response) => {
          console.log("getPayment", response);
          this.payer = response.data.remain_payment;
          this.payment_history = response.data.payment_history;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    updatePayment() {
      this.$store
        .dispatch("group/updatePayment", {
          payerID: this.payerID,
          payment: Number(this.payer_payment),
        })
        .then((response) => {
          console.log("updatePayment", response);
          this.$router.push({ name: "Group_balance" });
        })
        .catch((error) => {
          alert(error);
        });
    },
    deletePayment() {
      this.$store
        .dispatch("group/deletePayment", this.nowpickPayment.payment_id)
        .then((response) => {
          console.log("deletePayment", response);
          this.$router.push({ name: "Group_balance" });
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>