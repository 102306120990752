<template>
  <div class="mainFrame">
    <div class="status">
      <router-link to="/Group">
        <div class="active">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Group_balance' }">
        <div class="">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div v-if="!editName" @click="editName = !editName">
        {{ groupName }}
      </div>
      <input
        v-if="editName"
        class="inputTheme text22"
        style="width: 60%"
        type="text"
        placeholder="編輯群組名"
        v-model="newGroupName"
        @change="editGroupName"
      />
      <div class="flex">
        <span>{{ nowGroup.created_at | moment("YYYY.MM.DD") }}</span>
        <span
          :class="[{ hightLight: deleteView }, 'trashcan']"
          v-if="
            nowGroup.group_owner
              ? nowGroup.group_owner._id == userprofile._id
              : ''
          "
          @click="deleteView = true"
          ><i class="fas fa-trash-alt"></i
        ></span>
      </div>
    </div>

    <div class="finGroupContent">
      <div class="myMoney xcenter">
        <div>我的款項</div>
        <div class="flex">
          <img
            class="circleimg"
            :src="nowGroup.my_payment ? nowGroup.my_payment.image_url : ''"
            alt=""
          />
          <span class="myname">{{
            nowGroup.my_payment ? nowGroup.my_payment.display_name : "用戶名"
          }}</span>
          <span class="money">{{
            nowGroup.my_payment ? nowGroup.my_payment.payment : "金額"
          }}</span>
        </div>
      </div>

      <router-link :to="{ name: 'Group_member', params: { id: this.groupID } }">
        <div class="groupList xcenter">
          <div class="flex">
            <span>分攤成員</span>
            <span class="righticon"><i class="fas fa-chevron-right"></i></span>
          </div>

          <div class="flex">
            <img
              v-for="(item, index) in groupMembers"
              :key="index"
              class="circleimg"
              :src="item.image_url"
              alt=""
            />
          </div>
        </div>
      </router-link>

      <div class="project xcenter">
        <router-link
          :to="{ name: 'Group_itemlist', params: { id: this.groupID } }"
        >
          <div class="flex">
            <span>項目</span>
            <span class="righticon"><i class="fas fa-chevron-right"></i></span>
          </div>
        </router-link>

        <div
          class="eachProject xcenter"
          v-for="(item, index) in nowGroup.items"
          :key="index"
        >
          <span>{{ item.item_name }}</span>
          <span class="money">-{{ item.spend }}</span>
        </div>
      </div>

      <div class="totalmoney">總金額：{{ nowGroup.total_spend }}</div>

      <v-buttonList
        :trueView="trueView"
        :deleteView="deleteView"
        :btnList_delete="btnList_delete"
        @deleteCancel="cancel"
        @deleteAct="deleteGroup"
      >
      </v-buttonList>
    </div>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "group",
  data() {
    return {
      spinnerShow: false,
      trueView: false,
      btnList_delete: ["刪除", "取消"],
      deleteView: false,
      groupID: this.$route.params.id,
      groupName: "",
      newGroupName: "",
      editName: false,
    };
  },
  created() {
    this.sendGroupID(this.groupID);
  },
  mounted() {
    this.spinnerShow = true;
    this.getNowGroup();
    this.getMember();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      userprofile: (state) => state.user.userProfile,
      nowGroup: (state) => state.group.nowGroup,
      groupMembers: (state) => state.group.groupMember,
    }),
  },
  methods: {
    getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          console.log("現在群組", response.data);
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getMember() {
      this.$store
        .dispatch("group/getGroupMember")
        .then((response) => {
          console.log("memeber", response);
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    sendGroupID(groupID) {
      this.$store.dispatch("group/setNowGroupID", groupID);
    },
    deleteGroup() {
      this.$store
        .dispatch("group/deleteGroup")
        .then((response) => {
          console.log("delete", response);
          this.$router.push({ name: "Home_ing" });
        })
        .catch((error) => {
          alert(error);
        });
    },
    editGroupName: function () {
      this.editName = false;
      this.groupName = this.newGroupName;
    },
    cancel() {
      this.deleteView = false;
    },
  },
};
</script>