<template>
  <div class="mainFrame">
      <div class="status">
        <router-link to="/">
          <div class="active">
            新增項目
            <span></span>
          </div>
        </router-link>

        <router-link to="/Add_group">
          <div class="">新增群組</div>
        </router-link>
      </div>

      <div class="finAddContent">
        <div class="addContentList xcenter">
          <div class="Title">項目名稱：</div>
          <div class="addContent">
            <input
              class="inputTheme"
              type="text"
              v-model="itemTitle"
              placeholder="請輸入品項名稱"
            />
          </div>
        </div>
        <div class="addContentList xcenter" style="border: none">
          <div class="Title">項目價格：</div>
          <div class="addContent">
            <input
              class="inputTheme"
              type="text"
              v-model="itemPrice"
              placeholder="請輸入項目價格"
            />
          </div>
        </div>
        <div class="addContentList xcenter">
          <div class="Title">選擇群組：</div>
          <div
            class="addContent"
            v-if="chooseGroup"
            @click="chooseGroup = !chooseGroup"
          >
            {{ pickedGroup.group_name }}
          </div>
          <div v-if="!chooseGroup">
            <div class="addContent" style="color: #f35e5e">
              {{ pickedGroup.group_name }}(預設)
            </div>
            <ul class="addItem_chooseGroup">
              <li
                v-for="(item, index) in groupIng"
                :key="index"
                @click="changeGroup(item)"
              >
                <span class="itemTitle">{{ item.group_name }}</span>
                <span class="itemTime">{{
                  item.created_at | moment("YYYY.MM.DD")
                }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="Memberdiv xcenter">
          <div class="Title">選擇分擔成員：</div>
          <div class="selectAll" @click="allChoose">全選</div>

          <div class="memberImgDiv">
            <div
              class="memberImg"
              v-for="(item, index) in members"
              :key="index"
              @click="item.pick = !item.pick"
            >
              <img
                :class="[{ hightLightImg: item.pick }, 'circleimg']"
                :src="item.image_url"
                alt=""
              />
              <span :class="[{ hightLight: item.pick }]">{{
                item.display_name
              }}</span>
            </div>
          </div>
        </div>
      </div>
    

    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_sure="btnList_sure"
      @sureAct="addItem"
      @sureCancel="cancel"
    >
    </v-buttonList>
    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Add_item",
  data() {
    return {
      spinnerShow: false,
      trueView: true,
      btnList_sure: ["新增", "取消"],
      deleteView: false,
      chooseGroup: true,
      itemTitle: "",
      itemPrice: "",
      pickedGroup: {},
      pickedGroupID: "",
      members: [],
      pickedMember: [],
      pickAllMember: false,
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getGroupIng();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      groupIng: (state) => state.group.groupING,
    }),
  },
  methods: {
    getGroupIng() {
      this.$store
        .dispatch("group/getGroupIng")
        .then(() => {
          this.nowPickGroup();
        })
        .catch((error) => {
          console.log(error);
        })
    },
    nowPickGroup() {
      this.pickedGroup = this.groupIng[0];
      this.pickedGroupID = this.pickedGroup._id;
      this.getPickedGroupMember();
    },
    changeGroup(value) {
      this.chooseGroup = !this.chooseGroup;
      this.pickedGroup = value;
      this.pickedGroupID = value._id;
      this.getPickedGroupMember();
    },

    getPickedGroupMember() {
      this.$store
        .dispatch("add/getPickedGroupMember", {
          pickedGroupID: this.pickedGroupID,
        })
        .then((response) => {
          console.log("getPickedGroupMember", response.data);
          response.data.forEach((value) => {
            value.pick = false;
          });
          this.members = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    allChoose() {
      if (this.pickAllMember) {
        // 全取消
        this.members.forEach((value) => {
          value.pick = false;
        });
        this.pickAllMember = false;
      } else {
        // 全選
        this.members.forEach((value) => {
          value.pick = true;
        });
        this.pickAllMember = true;
      }
    },
    calmember() {
      this.pickedMember = [];
      this.members.forEach((value) => {
        console.log(value);
        if (value.pick == true) {
          this.pickedMember.push(value._id);
        }
      });

      console.log("pickedMember", this.pickedMember);
    },
    checkIfNone() {
      if (
        this.itemTitle == "" ||
        Number(this.itemPrice) <= 0 ||
        isNaN(Number(this.itemPrice)) == true
      ) {
        return false;
      } else {
        return true;
      }
    },
    addItem() {
      this.calmember();
      if (this.checkIfNone() == true && this.pickedMember.length > 0) {
        this.$store
          .dispatch("add/addItem", {
            pickedGroupID: this.pickedGroupID,
            item_name: this.itemTitle,
            shared_by: this.pickedMember,
            spend: Number(this.itemPrice),
          })
          .then((response) => {
            console.log("additem", response);
            this.$router.push({ name: "Home_ing" });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("請確認資料格式以及選取分攤人員！");
      }
    },
    cancel() {
      this.$router.push({ name: "Home_ing" });
    },
  },
};
</script>