import axios from "../axios/index";
import * as types from "./mutationType";

const state = {
  // group
  newGroupID: "",
  newGroup: {},

  // item
};

const mutations = {
  [types.setNewGroupID](state, items) {
    state.newGroupID = items;
  },
  [types.setNewGroup](state, items) {
    state.newGroup = items;
  },
};

const actions = {
  async addGroup(context, data) {
    try {
      let response = await axios({
        method: "post",
        url: `/group`,
        data: {
          group_name: data.groupTitle,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      context.commit(types.setNewGroupID, response.data._id);
      context.commit(types.setNewGroup, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async addfriendToGroup(context, data) {
    try {
      let response = await axios({
        method: "put",
        url: `/group/${state.newGroupID}/member`,
        data: {
          members_id: data,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getAddMemberToken() {
    try {
      let response = await axios({
        method: "post",
        url: `/group/${state.newGroupID}/member/invite`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getPickedGroupMember(context,data) {
    try {
      let response = await axios.get(`/group/${data.pickedGroupID}/member`);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async addItem(context,data) {
    try {
      let response = await axios({
        method: "put",
        url: `/group/${data.pickedGroupID}/items`,
        data: {
          item_name: data.item_name,
          shared_by: data.shared_by,
          spend: Number(data.spend),
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
