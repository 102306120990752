import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

// home
import Home_ing from "../views/home/Home_ing.vue";
import Home_finish from "../views/home/Home_finish.vue";

// add
import Add_item from "../views/add/Add_item.vue";
import Add_group from "../views/add/Add_group.vue";

// Group
import Group from "../views/group/Group.vue";
import Group_member from "../views/group/Group_member.vue";
import Group_itemlist from "../views/group/Group_itemlist.vue";
import Group_edititem from "../views/group/Group_edititem.vue";
import Group_balance from "../views/group/Group_balance.vue";
import Group_payment from "../views/group/Group_payment.vue";

// activity
import Activity_receive from "../views/activity/Activity_receive.vue";
import Activity_paid from "../views/activity/Activity_paid.vue";

// profile
import Profile from "../views/profile/profile.vue";
import Profile_setting from "../views/profile/profile_setting.vue";

import addmember from "../views/addmember.vue";
import addfriend from "../views/addfriend.vue";
import edit_itemMember from "../views/edit_itemMember.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home_ing",
    component: Home_ing,
  },
  {
    path: "/Home_finish",
    name: "Home_finish",
    component: Home_finish,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Add_item",
    name: "Add_item",
    component: Add_item,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Add_group",
    name: "Add_group",
    component: Add_group,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group_member:id?",
    name: "Group_member",
    component: Group_member,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group_itemlist:id?",
    name: "Group_itemlist",
    component: Group_itemlist,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group_edititem:id?",
    name: "Group_edititem",
    component: Group_edititem,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group_balance:id?",
    name: "Group_balance",
    component: Group_balance,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group_payment:id?",
    name: "Group_payment",
    component: Group_payment,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Group:id?",
    name: "Group",
    component: Group,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/edit_itemMember:id?",
    name: "edit_itemMember",
    component: edit_itemMember,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/addmember",
    name: "addmember",
    component: addmember,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Activity_receive",
    name: "Activity_receive",
    component: Activity_receive,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Activity_paid",
    name: "Activity_paid",
    component: Activity_paid,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Profile",
    name: "Profile",
    component: Profile,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/Profile_setting",
    name: "Profile_setting",
    component: Profile_setting,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/addfriend",
    name: "addfriend",
    component: addfriend,
    meta: {
      requireAuth: true,
    },
  },
  // {
  //   path: "*",
  //   name: "Home_ing",
  //   component: Home_ing,
  // },
];

const router = new VueRouter({
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (store.state.user.userData) {
//       next();
//     } else {
//       next({ name: "Home_ing" });
//     }
//   } else {
//     next();
//   }
// });

router.beforeEach(async (to, from, next) => {
  await window.liff.init({
    liffId: window.config.liffId,
  });

  if (!window.liff.isLoggedIn()) {
    console.log("沒登入");

    window.liff
      .login({
        redirectUri: window.config.redirectUri,
      })
      .then(() => {
        console.log("登入成功！");
      });

    next();
  } else {
    console.log("已登入");
    let IDtoken = await window.liff.getIDToken();
    // 把ID_token存進store
    await store.dispatch("user/setIDToken", IDtoken);
    // 驗證Token
    await store
      .dispatch("user/sendToken")
      .then((response) => {
        console.log("sendToken", response.data);
      })
      .catch((err) => {
        console.log("sendTokenERR", err);
      });

    // 拿取用戶資訊
    await store.dispatch("user/getProfile");

    //如果是要新增成員則直接跳轉
    // if (to.name == "addmember" || to.name == "addfriend") {
    //   console.log(to, "要去哪裡呀");
    //   next();
    // } else if (
    //   //賴本身瀏覽器轉跳頁面bug,加上條件判別解決沒跳轉問題
    //   navigator.userAgent.indexOf("Line") > -1 &&
    //   !sessionStorage.getItem("firstTimeVist")
    // ) {
    //   sessionStorage.setItem("firstTimeVist", 0);
    //   next({ name: "Home_ing" });
    // } else {
    //   next();
    // }
    next();
  }
});

export default router;
