<template>
  <div id="app">
    <v-header></v-header>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div class="mainFrameOutter">
          <router-view v-if="userprofile.display_name" />
    </div>
    <v-footer></v-footer>
  </div>
</template>

<script>
import "@/assets/css/app.scss";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {},
  mounted() {
    this.safariHacks();
  },
  computed: {
    ...mapState({
      userprofile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    safariHacks() {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      // We listen to the resize event
      window.addEventListener("resize", () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        console.log(vh);
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });
    },
  },
};
</script>

<style scoped>
</style>
