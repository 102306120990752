<template>
  <div class="mainFrame">
    <div class="status" style="margin-bottom: 30px">
      <router-link to="/Activity_receive">
        <div class="">
          收入
          <span></span>
        </div>
      </router-link>

      <router-link to="/Activity_paid">
        <div class="active">
          支出
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="finListDiv">
      <div class="bordertop_activity"></div>
      <div
        class="finList_activity"
        v-for="(item, index) in paidData"
        :key="index"
      >
        <div class="finDate" style="border: none">
          <div class="allcenter">
            <div class="mounth" v-if="item.dateShow">
              {{ item.paid_at | moment("MMM") }}.
            </div>
            <div class="date" v-if="item.dateShow">
              {{ item.paid_at | moment("DD") }}
            </div>
          </div>
        </div>

        <div class="finContent">
          <div class="Title_activity">
            <div class="hightLight">{{ item.group_name }}</div>
            <div>
              你<span class="hightLight">付給</span>{{ item.payee }}
              <span class="money text18">{{ item.payment }}.</span>
            </div>

            <div class="text12 textgrey3 receiveTime">
              {{ item.paid_at | moment("hh:mm:ss") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
export default {
  name: "Activity_paid",
  data() {
    return {
      spinnerShow: false,
      paidData: [],
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getPaid();
  },
  methods: {
    getPaid() {
      this.$store
        .dispatch("user/getPaid")
        .then((response) => {
          console.log("paidData", response);
          this.paidData = response.data;
          this.dateFilter();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    dateFilter() {
      let _this = this;
      let firstOne = _this.paidData[0].paid_at;

      let showTop = _this.$moment(firstOne * 1000).format("YYYY-MM-DD");

      _this.paidData.forEach((value, index) => {
        if (index == 0) {
          value.dateShow = true;
        } else if (
          _this.$moment(value.paid_at * 1000).format("YYYY-MM-DD") == showTop
        ) {
          value.dateShow = false;
        } else if (
          _this.$moment(value.paid_at * 1000).format("YYYY-MM-DD") != showTop
        ) {
          showTop = _this.$moment(value.paid_at * 1000).format("YYYY-MM-DD");
          value.dateShow = true;
        }
      });
    },
  },
};
</script>