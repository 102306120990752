<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="active">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'Group_balance', params: { id: this.groupID } }"
      >
        <div class="">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ nowGroup.group_name }}</div>
      <div class="flex">
        <span>{{ nowGroup.created_at | moment("YYYY.MM.DD") }}</span>
      </div>
    </div>

    <div class="Memberdiv_groupMember xcenter">
      <div class="Title">分擔成員：</div>

      <div class="Memberdiv_groupMember_inner xcenter">
        <div class="memberImgDiv">
          <div class="memberImg" @click="addmember">
            <img
              class="circleimg"
              src="/images/add.png"
              alt=""
              style="border: 1px solid #d5d4d4"
            />
            <span>新增成員</span>
          </div>
          <div
            class="memberImg"
            v-for="(item, index) in groupMembers"
            :key="index"
            @click="chooseMember(item, index)"
          >
            <img
              :class="[{ hightLightImg: nowpickIndex == index }, 'circleimg']"
              :src="item.image_url"
              alt=""
            />
            <span :class="{ hightLight: nowpickIndex == index }">{{
              item.display_name
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 按鈕組件化 -->
    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_delete="btnList_delete"
      @deleteCancel="cancel"
      @deleteAct="deleteGroupMember"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Group_member",
  data() {
    return {
      spinnerShow: false,
      trueView: false,
      btnList_delete: ["刪除", "取消"],
      deleteView: false,
      nowpickMember: {},
      nowpickIndex: null,
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getAddMemberToken();
    this.getNowGroup();
    this.getMember();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      userprofile: (state) => state.user.userProfile,
      nowGroup: (state) => state.group.nowGroup,
      groupMembers: (state) => state.group.groupMember,
      addMemberToken: (state) => state.group.addMemberToken,
      groupID: (state) => state.group.nowGroupID,
    }),
  },
  methods: {
     getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(()=>{
        })
    },
    getMember() {
      this.$store
        .dispatch("group/getGroupMember")
        .then((response) => {
          console.log("memeber", response);
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    chooseMember(item, index) {
      this.deleteView = !this.deleteView;
      this.nowpickMember = item;
      this.nowpickIndex = index;
      console.log(this.nowpickMember);
    },
    cancel() {
      this.deleteView = false;
      this.nowpickIndex = null;
    },
    deleteGroupMember() {
      if (this.nowGroup.group_owner._id == this.userprofile._id) {
        this.$store
          .dispatch("group/deleteGroupMember", {
            member_id: this.nowpickMember._id,
          })
          .then((response) => {
            console.log("deleteGroupMember", response);
            this.$router.push({
              name: "Group",
              params: { id: this.groupID },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("創建人才有權利修改成員！！");
      }
    },
    getAddMemberToken: function () {
      this.$store
        .dispatch("group/getAddMemberToken")
        .then((response) => {
          console.log("AddMemberToken", response.data);
          console.log("groupID", this.groupID);
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    addmember: function () {
      console.log(window.liff.isApiAvailable("shareTargetPicker"));

      window.liff
        .shareTargetPicker([
          {
            type: "flex",
            altText: "Flex Message",
            contents: {
              type: "bubble",
              body: {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: `${window.config.redirectUri}/logo.png`,
                    size: "full",
                    aspectMode: "cover",
                    aspectRatio: "2:3",
                    gravity: "top",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "text",
                            text: "加入我的Fintake群組吧！",
                            size: "xl",
                            color: "#ffffff",
                            weight: "bold",
                          },
                        ],
                      },
                      {
                        type: "box",
                        layout: "baseline",
                        contents: [
                          {
                            type: "text",
                            text: "群組名稱：",
                            color: "#ebebeb",
                            size: "sm",
                            flex: 0,
                          },
                          {
                            type: "text",
                            text: this.nowGroup.group_name,
                            color: "#ffffffcc",
                            decoration: "none",
                            gravity: "bottom",
                            flex: 0,
                            size: "sm",
                          },
                        ],
                        spacing: "lg",
                      },
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "filler",
                          },
                          {
                            type: "box",
                            action: {
                              type: "uri",
                              uri: `${window.config.redirectUri}/#/addmember?groupID=${this.groupID}&token=${this.addMemberToken}`,
                            },
                            layout: "baseline",
                            contents: [
                              {
                                type: "filler",
                              },
                              {
                                type: "icon",
                                url: "https://cdn1.iconfinder.com/data/icons/security-law/500/law_iconsArtboard_1_copy_3-256.png",
                              },
                              {
                                type: "text",
                                text: "加入",
                                color: "#ffffff",
                                flex: 0,
                                offsetTop: "-2px",
                              },
                              {
                                type: "filler",
                              },
                            ],
                            spacing: "sm",
                          },
                          {
                            type: "filler",
                          },
                        ],
                        borderWidth: "1px",
                        cornerRadius: "4px",
                        spacing: "sm",
                        borderColor: "#ffffff",
                        margin: "xxl",
                        height: "40px",
                      },
                    ],
                    position: "absolute",
                    offsetBottom: "0px",
                    offsetStart: "0px",
                    offsetEnd: "0px",
                    backgroundColor: "#03303Acc",
                    paddingAll: "20px",
                    paddingTop: "18px",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "Join My Fintake Group !",
                        color: "#ffffff",
                        align: "center",
                        size: "xs",
                        offsetTop: "3px",
                      },
                    ],
                    position: "absolute",
                    cornerRadius: "20px",
                    offsetTop: "18px",
                    backgroundColor: "#F35E5E",
                    offsetStart: "20%",
                    height: "25px",
                    width: "60%",
                  },
                ],
                paddingAll: "0px",
              },
            },
          },
        ])
        .then()
        .catch(function (error) {
          console.log(error);
          alert("發送失敗！");
        });
    },
  },
};
</script>