<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="active">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link
        :to="{ name: 'Group_balance', params: { id: this.groupID } }"
      >
        <div class="">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ nowGroup.group_name }}</div>
      <div class="flex">
        <span>{{ nowGroup.created_at | moment("YYYY.MM.DD") }}</span>
      </div>
    </div>

    <div class="finGroupContent">
      <div class="groupList_large xcenter" style="border: none">
        <div class="flex">
          <div class="wholediv">
            <span>編輯項目</span>
            <span
              :class="[{ hightLight: deleteView }, 'trashcan']"
              v-if="nowItem ? nowItem.owner._id == userprofile._id : ''"
              @click="deleteView = true"
              ><i class="fas fa-trash-alt"></i
            ></span>
          </div>
        </div>

        <div class="groupList_2">
          <div class="Title">項目名稱：</div>
          <div class="groupContent">
            <input
              class="inputTheme"
              type="text"
              v-model="itemName"
              placeholder="請輸入項目名稱"
            />
          </div>
        </div>

        <div class="groupList_2">
          <div class="Title">項目價格：</div>
          <div class="groupContent">
            <input
              class="inputTheme"
              type="text"
              v-model="itemPrice"
              placeholder="請輸入項目價格"
            />
          </div>
        </div>

        <div class="groupList_2">
          <div class="Title textgrey2 text18">創建者</div>
          <div class="flex">
            <img
              class="circleimg"
              :src="nowItem ? nowItem.owner.image_url : ''"
              alt=""
            />
            <span class="myname">{{
              nowItem ? nowItem.owner.display_name : ""
            }}</span>
            <span class="money">{{
              nowItem ? nowItem.owner.payment.toFixed(2) : ""
            }}</span>
          </div>
        </div>

        <div class="Memberdiv xcenter">
          <div class="Title">分擔成員：</div>
          <router-link
            :to="{
              name: 'edit_itemMember',
              params: { id: this.groupID, item_id: this.itemID },
            }"
          >
            <span
              class="selectAll"
              v-if="nowItem ? nowItem.owner._id == userprofile._id : ''"
              >編輯項目成員</span
            >
          </router-link>
          <div
            class="memberImg"
            v-for="(item, index) in shareMember"
            :key="index"
          >
            <img class="circleimg" :src="item.image_url" alt="" />
            <span class="">{{ item.display_name }}</span>
          </div>
        </div>
      </div>

      <!-- 留一頁未組件化的參考 -->
      <!-- <div class="finBtnList" v-if="!deleteView">
      <button class="activeBtn" @click="editItem">確認</button>
      <button class="cancel" @click="cancel">取消</button>
    </div>

    <div v-if="deleteView" class="deleteView"></div>
    <div v-if="deleteView" class="deleteBtnList">
      <button class="deleteBtn" @click="deleteItem">刪除</button>
      <button class="cancel" @click="cancel">取消</button>
    </div> -->
      <v-buttonList
        :trueView="trueView"
        :deleteView="deleteView"
        :btnList_sure="btnList_sure"
        :btnList_delete="btnList_delete"
        @sureAct="editItem"
        @sureCancel="sureCancel"
        @deleteAct="deleteItem"
        @deleteCancel="cancel"
      >
      </v-buttonList>
    </div>
    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Group_edititem",
  data() {
    return {
      spinnerShow: false,
      trueView: true,
      btnList_sure: ["確認", "取消"],
      btnList_delete: ["刪除", "取消"],
      deleteView: false,
      itemID: this.$route.params.item_id,

      // 方便修改項目
      itemName: "",
      itemPrice: "",
      shareMember: [],
      itemMemberID: [],
    };
  },
  created() {
    this.sendItemID(this.itemID);
  },
  mounted() {
    this.spinnerShow = true;
    this.getNowGroup();
    this.getNowItem();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      userprofile: (state) => state.user.userProfile,
      nowGroup: (state) => state.group.nowGroup,
      groupMember: (state) => state.group.groupMember,
      nowItem: (state) => state.group.nowItem,
      groupID: (state) => state.group.nowGroupID,
    }),
  },
  methods: {
    sendItemID(itemID) {
      this.$store.dispatch("group/setNowItemID", itemID);
    },
    getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          console.log("現在群組", response.data);
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        });
    },
    getNowItem() {
      this.$store
        .dispatch("group/getNowItem")
        .then((response) => {
          console.log("nowitem", response.data);
          this.itemName = response.data.item_name;
          this.itemPrice = response.data.spend;
          this.shareMember = response.data.shared_by;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    editItem: function () {
      // 項目創建人才可以修改
      if (this.nowItem.owner._id == this.userprofile._id) {
        this.getItemMemberID();
        this.$store
          .dispatch("group/editItem", {
            itemName: this.itemName,
            itemMemberID: this.itemMemberID,
            itemPrice: Number(this.itemPrice),
          })
          .then((response) => {
            console.log("editItem", response);
            this.$router.push({ name: "Group_itemlist" });
          })
          .catch((error) => {
            alert(error);
          });
      } else {
        alert("項目創建人才有權利修改！！");
      }
    },
    deleteItem: function () {
      this.$store
        .dispatch("group/deleteItem")
        .then((response) => {
          console.log("deleteItem", response);
          this.$router.push({ name: "Group_itemlist" });
        })
        .catch((error) => {
          alert(error);
        });
    },
    getItemMemberID: function () {
      this.itemMemberID = [];
      this.shareMember.forEach((value) => {
        this.itemMemberID.push(value._id);
      });
    },
    sureCancel() {
      this.$router.push({ name: "Group_itemlist" });
    },
    cancel() {
      this.deleteView = false;
    },
  },
};
</script>