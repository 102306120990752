import axios from "../axios/index";
import * as types from "./mutationType";

const state = {
  // group
  groupING: {},
  nowGroupID: "",
  nowGroup: {},
  groupMember: [],
  addMemberToken: "",
  groupBalance: {},

  // item in group
  nowItemID: "",
  nowItem: "",
};

const mutations = {
  // group
  [types.setGroupING](state, items) {
    state.groupING = items;
  },
  [types.setNowGroupID](state, items) {
    state.nowGroupID = items;
  },
  [types.setNowGroup](state, items) {
    state.nowGroup = items;
  },
  [types.setGroupMember](state, items) {
    state.groupMember = items;
  },
  [types.setAddMemberToken](state, items) {
    state.addMemberToken = items;
  },
  [types.setGroupBalance](state, items) {
    state.groupBalance = items;
  },

  // item in group
  [types.setNowItemID](state, items) {
    state.nowItemID = items;
  },
  [types.setNowItem](state, items) {
    state.nowItem = items;
  },
};

const actions = {
  async setNowGroupID(context, data) {
    await context.commit(types.setNowGroupID, data);
  },
  async getGroupIng(context) {
    try {
      let response = await axios.get("/group");
      context.commit(types.setGroupING, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getGroupFinish() {
    try {
      let response = await axios.get("/group/finished");
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getNowGroup(context) {
    try {
      let response = await axios.get(
        `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }`
      );
      context.commit(types.setNowGroup, response.data);

      if (!state.nowGroupID) {
        context.commit(types.setNowGroupID, localStorage.getItem("nowGroupID"));
      }
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getGroupMember(context) {
    try {
      let response = await axios.get(
        `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }/member`
      );
      context.commit(types.setGroupMember, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteGroupMember(context, data) {
    try {
      let response = await axios({
        method: "delete",
        url: `/group/${state.nowGroupID}/member/${data.member_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getAddMemberToken(context) {
    try {
      let response = await axios({
        method: "post",
        url: `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }/member/invite`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      context.commit(types.setAddMemberToken, response.data.invite_token);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteGroup() {
    try {
      let response = await axios({
        method: "delete",
        url: `/group/${state.nowGroupID}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getGroupBalance(context) {
    try {
      let response = await axios.get(
        `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }/balance`
      );
      context.commit(types.setGroupBalance, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  // item
  async setNowItemID(context, data) {
    await context.commit(types.setNowItemID, data);
  },
  async getNowItem(context) {
    try {
      let response = await axios.get(
        `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }/items/${
          state.nowItemID ? state.nowItemID : localStorage.getItem("nowItemID")
        }`
      );
      context.commit(types.setNowItem, response.data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async editItem(context, data) {
    try {
      let response = await axios({
        method: "patch",
        url: `/group/${state.nowGroupID}/items/${state.nowItemID}`,
        data: {
          item_name: data.itemName,
          shared_by: data.itemMemberID,
          spend: Number(data.itemPrice),
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteItem() {
    try {
      let response = await axios({
        method: "delete",
        url: `/group/${state.nowGroupID}/items/${state.nowItemID}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async getPayment(context, payerID) {
    try {
      let response = await axios.get(
        `/group/${
          state.nowGroupID
            ? state.nowGroupID
            : localStorage.getItem("nowGroupID")
        }/payment/${payerID ? payerID : localStorage.getItem("payerID")}`
      );
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updatePayment(context, paymentData) {
    try {
      let response = await axios({
        method: "post",
        url: `/group/${state.nowGroupID}/payment/${paymentData.payerID}`,
        data: {
          payment: Number(-paymentData.payment),
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deletePayment(context, paymentID) {
    try {
      let response = await axios({
        method: "delete",
        url: `/group/${state.nowGroupID}/payment/${paymentID}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
