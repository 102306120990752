<template>
  <div>
    <div class="mainFrame">
      <div v-if="login">登入成功</div>
      <div v-if="!login">登入失敗</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "addmember",
  data() {
    return {
      login: false,
      IDtoken: "",
      groupID: this.$router.app._route.query.groupID,
      addGrouptoken: this.$router.app._route.query.token,
    };
  },
  beforeCreate() {
    window.liff
      .init({
        liffId: window.config.liffId,
      })
      .then(() => {
        if (!window.liff.isLoggedIn()) {
          this.logIn();
        } else {
          this.login = true;
          this.getToken();
        }
      });
  },
  mounted() {
    console.log(this.$router.app._route.query);
    console.log("groupID", this.groupID);
    console.log("addGrouptoken", this.addGrouptoken);
  },
  methods: {
    logIn: function () {
      window.liff.login({
        redirectUri: `${window.config.redirectUri}/#/addmember?groupID=${this.groupID}&token=${this.addGrouptoken}`,
      });
    },
    getToken() {
      this.IDtoken = window.liff.getIDToken();
      // 把ID_token存進store
      this.$store.dispatch("user/setIDToken", this.IDtoken);
      this.sendToken();
    },
    sendToken() {
      this.$store
        .dispatch("user/sendToken")
        .then((response) => {
          console.log("sendToken",response.data);
          this.getProfile();
          this.addInGroup();
        })
        .catch((err) => {
          alert(err);
          // window.localStorage.clear();
          // window.liff.logout();
        });
    },
    getProfile() {
      this.$store.dispatch("user/getProfile");
    },
    addInGroup: function () {
      this.$axios({
        method: "put",
        url: `/group/${this.groupID}/member/invite`,
        params: {
          token: this.addGrouptoken,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log("addingroup", res);
          this.$router.push({ name: "Home_ing" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>