<template>
  <div class="mainFrame">
    <div class="status">
      <router-link :to="{ name: 'Group', params: { id: this.groupID } }">
        <div class="">
          群組
          <span></span>
        </div>
      </router-link>

      <router-link :to="{ name: 'Group_balance' }">
        <div class="active">
          分攤明細
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="groupTitle">
      <div>{{ groupBalance.group_name }}</div>
      <div class="flex">
        <span>{{ groupBalance.created_at | moment("YYYY.MM.DD") }}</span>
      </div>
    </div>

    <div class="groupList_balance groupList_large xcenter">
      <div
        class="groupList_2 flex"
        v-for="(item, index) in groupBalance.balance"
        :key="index"
      >
        <div class="Title_balance">
          {{ item.payer.display_name }}<span class="hightLight">需付給</span
          >{{ item.payee.display_name }}

          <span class="money right20_balance">{{
            item.payment.toFixed(2)
          }}</span>

          <!-- <router-link :to="{ name: 'Group_payment' }">
          </router-link> -->

          <span
            class="money width10_balance"
            v-if="item.payee.display_name == userName"
            @click="payerPayment(item.payer)"
          >
            <i class="fas fa-chevron-right hightLight"></i>
          </span>
        </div>
      </div>
    </div>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Group_balance",
  data() {
    return {
      spinnerShow:false,
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getGroupBalance();
    this.getNowGroup();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      userID: (state) => state.user.userData._id,
      userName:(state)=>state.user.userData.display_name,
      groupBalance: (state) => state.group.groupBalance,
      groupID: (state) => state.group.nowGroupID,
    }),
  },
  methods: {
    getNowGroup() {
      this.$store
        .dispatch("group/getNowGroup")
        .then((response) => {
          this.groupName = response.data.group_name;
        })
        .catch((error) => {
          alert(error);
        })
        .finally(()=>{
        })
    },
    getGroupBalance: function () {
      this.$store
        .dispatch("group/getGroupBalance")
        .then((response) => {
          console.log("groupBalance", response.data);
        })
        .catch((error) => {
          alert(error);
        })
        .finally(()=>{
          this.spinnerShow = false;
        })
    },
    payerPayment(payer) {
      // 同時存取payerID進localstorage裡
      window.localStorage.setItem('payerID',payer._id);

      this.$router.push({
        name: "Group_payment",
        params: { id: this.groupID, payerID: payer._id },
      });
    },
  },
};
</script>