<template>
  <div class="mainFrame">
    <div class="status">
      <router-link to="/Add_item">
        <div class="">
          新增項目
          <span></span>
        </div>
      </router-link>

      <router-link to="/Add_group">
        <div class="active">
          新增群組
          <span></span>
        </div>
      </router-link>
    </div>

    <div class="finAddContent">
      <div class="addContentList xcenter">
        <div class="Title">群組名稱：</div>
        <div class="addContent">
          <input
            class="inputTheme"
            type="text"
            v-model="groupTitle"
            placeholder="請輸入群組名稱"
          />
        </div>
      </div>

      <div class="Memberdiv_addGroup xcenter">
        <div class="memberImgDiv" v-if="nextstep" @click="addmember">
          <div class="Title">邀請Line好友：</div>
          <div class="memberImg trashcan">
            <img
              class="circleimg"
              src="/images/add.png"
              alt=""
              style="border: 1px solid #d5d4d4"
            />
          </div>
        </div>

        <div class="memberImgDiv" v-if="nextstep">
          <div class="Title">選擇Fintake好友：</div>
          <div class="selectAll" @click="allChoose">全選</div>
          <div
            class="memberImg"
            v-for="(item, index) in userFriends"
            :key="index"
            @click="item.pick = !item.pick"
          >
            <img
              :class="[{ hightLightImg: item.pick }, 'circleimg']"
              :src="item.image_url"
              alt=""
            />
            <span :class="[{ hightLight: item.pick }]">{{
              item.display_name
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <v-buttonList
      :trueView="trueView"
      :deleteView="deleteView"
      :btnList_sure="btnList_sure"
      @sureAct="addGroupOrfriend"
      @sureCancel="cancel"
    >
    </v-buttonList>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Add_group",
  data() {
    return {
      spinnerShow: false,
      trueView: true,
      btnList_sure: ["下一步", "取消"],
      deleteView: false,
      groupTitle: "",
      userFriends: [],
      pickedFriend: [],
      nextstep: false,
      newGroup: {},
      newGroupID: "",
      addmemberToken: "",
      pickAllMember:false
    };
  },
  mounted() {
    this.getUserFriends();
  },
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      // userprofile: (state) => state.user.userProfile,
    }),
  },
  methods: {
    getUserFriends() {
      this.$store
        .dispatch("user/getProfile")
        .then((response) => {
          response.data.friend_list.forEach((value) => {
            this.$set(value, "pick", false);
          });

          this.userFriends = response.data.friend_list;
          console.log("userFriends", this.userFriends);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addGroupOrfriend() {
      if (this.nextstep == false) {
        this.addGroup();
      } else {
        this.addfriendToGroup();
      }
    },
    addGroup() {
      this.$store
        .dispatch("add/addGroup", { groupTitle: this.groupTitle })
        .then((response) => {
          console.log("addGroup", response);
          this.newGroupID = response.data._id;
          this.newGroup = response.data;
          this.getAddMemberToken();
        })
        .catch((error) => {
          alert(error);
        });
    },
    allChoose() {
      if (this.pickAllMember) {
        // 全取消
        this.userFriends.forEach((value) => {
          value.pick = false;
        });
        this.pickAllMember = false;
      } else {
        // 全選
        this.userFriends.forEach((value) => {
          value.pick = true;
        });
        this.pickAllMember = true;
      }

    },
    getAddMemberToken() {
      this.$store
        .dispatch("add/getAddMemberToken")
        .then((response) => {
          this.addmemberToken = response.data.invite_token;
          this.nextstep = true;

          console.log("AddMemberToken", this.addmemberToken);
          console.log("newGroupID", this.newGroupID);
        })
        .catch((error) => {
          alert(error);
        });
    },
    addfriendToGroup() {
      this.calfriend();

      this.$store
        .dispatch("add/addfriendToGroup", this.pickedFriend)
        .then((response) => {
          console.log("加好友進群組", response);
          this.$router.push({ name: "Home_ing" });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calfriend() {
      this.pickedFriend = [];
      this.userFriends.forEach((value) => {
        if (value.pick == true) {
          this.pickedFriend.push(value._id);
        }
      });

      console.log("pickedFriend", this.pickedFriend);
    },
    cancel(){
      this.$router.push({ name: "Home_ing" });
    },
    addmember: function () {
      console.log(window.liff.isApiAvailable("shareTargetPicker"));

      window.liff
        .shareTargetPicker([
          {
            type: "flex",
            altText: "Flex Message",
            contents: {
              type: "bubble",
              body: {
                type: "box",
                layout: "vertical",
                contents: [
                  {
                    type: "image",
                    url: `${window.config.redirectUri}/logo.png`,
                    size: "full",
                    aspectMode: "cover",
                    aspectRatio: "2:3",
                    gravity: "top",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "text",
                            text: "加入我的Fintake群組吧！",
                            size: "xl",
                            color: "#ffffff",
                            weight: "bold",
                          },
                        ],
                      },
                      {
                        type: "box",
                        layout: "baseline",
                        contents: [
                          {
                            type: "text",
                            text: "群組名稱：",
                            color: "#ebebeb",
                            size: "sm",
                            flex: 0,
                          },
                          {
                            type: "text",
                            text: this.newGroup.group_name,
                            color: "#ffffffcc",
                            decoration: "none",
                            gravity: "bottom",
                            flex: 0,
                            size: "sm",
                          },
                        ],
                        spacing: "lg",
                      },
                      {
                        type: "box",
                        layout: "vertical",
                        contents: [
                          {
                            type: "filler",
                          },
                          {
                            type: "box",
                            action: {
                              type: "uri",
                              uri: `${window.config.redirectUri}/#/addmember?groupID=${this.newGroupID}&token=${this.addmemberToken}`,
                            },
                            layout: "baseline",
                            contents: [
                              {
                                type: "filler",
                              },
                              {
                                type: "icon",
                                url: "https://cdn1.iconfinder.com/data/icons/security-law/500/law_iconsArtboard_1_copy_3-256.png",
                              },
                              {
                                type: "text",
                                text: "加入",
                                color: "#ffffff",
                                flex: 0,
                                offsetTop: "-2px",
                              },
                              {
                                type: "filler",
                              },
                            ],
                            spacing: "sm",
                          },
                          {
                            type: "filler",
                          },
                        ],
                        borderWidth: "1px",
                        cornerRadius: "4px",
                        spacing: "sm",
                        borderColor: "#ffffff",
                        margin: "xxl",
                        height: "40px",
                      },
                    ],
                    position: "absolute",
                    offsetBottom: "0px",
                    offsetStart: "0px",
                    offsetEnd: "0px",
                    backgroundColor: "#03303Acc",
                    paddingAll: "20px",
                    paddingTop: "18px",
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    contents: [
                      {
                        type: "text",
                        text: "Join My Fintake Group !",
                        color: "#ffffff",
                        align: "center",
                        size: "xs",
                        offsetTop: "3px",
                      },
                    ],
                    position: "absolute",
                    cornerRadius: "20px",
                    offsetTop: "18px",
                    backgroundColor: "#F35E5E",
                    offsetStart: "20%",
                    height: "25px",
                    width: "60%",
                  },
                ],
                paddingAll: "0px",
              },
            },
          },
        ])
        .then()
        .catch(function () {
          alert("發送失敗！");
        });
    },
  },
};
</script>