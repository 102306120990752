import axios from "axios";

// 全局配置
const instance = axios.create({
  baseURL: "https://dev-fintake-api.mouthird.com/v2.0",
  timeout: 10000,
  headers: {},
});

// 攔截
instance.interceptors.request.use(
  (config) => {
    if (config.headers.id_token) {
      return config;
    } else if (!config.headers.access_token) {
      config.headers.access_token = window.localStorage.getItem("access_token");

      // 返回 config 代表繼續發送請求
      return config;
    }
  },
  (err) => {
    console.log(new Error(err));
  }
);

// 響應攔截
instance.interceptors.response.use(
  // 成功響應處理
  (res) => {
    return res;
  },
  // 報錯響應處理
  (err) => console.log(new Error(err))
);

export default instance;
