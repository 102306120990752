<template>
  <div class="mainFrame">
    <div class="searchDiv">
      <input
        type="text"
        name=""
        id="searchBar"
        placeholder="搜尋"
        v-model="searchWord"
      />
      <div class="searchButton" @click="searchGroup">
        <i class="fas fa-crosshairs allcenter" style="color: #464545"></i>
      </div>
    </div>

    <div class="status">
      <router-link to="/">
        <div class="active">
          進行中
          <span></span>
        </div>
      </router-link>

      <router-link to="/Home_finish">
        <div class="">已完成</div>
      </router-link>
    </div>

    <div class="finListDiv">
      <div
        @click="goGroup(item._id)"
        v-for="item in displayGroup"
        :key="item._id"
      >
        <div class="finList">
          <div class="finDate" :style="completePercent(item.ratio)">
            <div class="allcenter">
              <div class="mounth">{{ item.created_at | moment("MMM") }}.</div>
              <div class="date">
                {{ item.created_at | moment("DD") }}
              </div>
            </div>
          </div>

          <div class="finContent">
            <div class="finTitle">{{ item.group_name }}</div>
            <div class="finTotle">總金額：{{ item.total_spend }}</div>
          </div>
        </div>
      </div>
    </div>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      spinnerShow: false,
      IDtoken: "",
      groupIng: [],
      displayGroup: [],
      searchWord: "",
    };
  },
  beforeCreate() {
    // window.liff
    //   .init({
    //     liffId: window.config.liffId,
    //   })
    //   .then(() => {
    //     if (!window.liff.isLoggedIn()) {
    //       this.logIn();
    //     } else {
    //       this.getToken();
    //     }
    //   });
  },
  mounted() {
    this.spinnerShow = true;
    console.log("version", window.liff.getVersion());
    console.log("group_ing", this.$store.state.group);
    this.getGroupIng();
  },
  beforeDestroy() {},
  computed: {
    ...mapState({
      access_token: (state) => state.user.userData.access_token,
      // groupIng: (state) => state.group.groupING,
    }),
  },
  methods: {
    logIn: function () {
      window.liff.login({
        redirectUri: window.config.redirectUri,
      });
    },
    getToken() {
      this.IDtoken = window.liff.getIDToken();
      // 把ID_token存進store
      this.$store.dispatch("user/setIDToken", this.IDtoken);
      this.sendToken();
    },
    sendToken() {
      this.$store
        .dispatch("user/sendToken")
        .then((response) => {
          console.log(response);
          console.log(localStorage);
          this.getProfile();
          this.getGroupIng();
        })
        .catch((err) => {
          alert(err);
          // window.localStorage.clear();
          // window.liff.logout();
        });
    },
    getProfile() {
      this.$store.dispatch("user/getProfile");
    },
    getGroupIng() {
      this.$store
        .dispatch("group/getGroupIng")
        .then((response) => {
          this.groupIng = response.data;
          console.log(response.data,"進行中群組")
          this.searchGroup();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.spinnerShow = false;
        });
    },
    completePercent(data) {
      return {
        "border-image": `-webkit-linear-gradient(rgb(243, 94, 94) ${data}%,transparent ${data}%)`,
        "border-image-slice": "1",
      };
    },
    searchGroup() {
      this.displayGroup = this.groupIng.filter((value) => {
        return value.group_name.toLowerCase().indexOf(this.searchWord.toLowerCase()) > -1;
      });
    },
    goGroup(id){
     // 同時存取groupID進localstorage裡
      window.localStorage.setItem('nowGroupID',id);

      this.$router.push({
        name: "Group",
        params: { id: id},
      });
    }
  },
};
</script>
