<template>
  <div class="mainFrame">
    <div class="searchDiv">
      <input type="text" name="" id="searchBar" placeholder="搜尋" />
      <div class="searchButton">
        <i class="fas fa-crosshairs allcenter" style="color: #464545"></i>
      </div>
    </div>

    <div class="status">
      <router-link to="/">
        <div>進行中</div>
      </router-link>

      <router-link to="/Home_finish">
        <div class="active">
          已完成
          <span></span>
        </div>
      </router-link>
    </div>
    <div class="finListDiv">
      <div class="finList" v-for="(item, index) in groupFinish" :key="index">
        <div class="finDate">
          <div class="allcenter">
            <div class="mounth" style="color: #959494">
              {{ item.created_at | moment("MMM") }}.
            </div>
            <div class="date" style="color: #959494">
              {{ item.created_at | moment("DD") }}
            </div>
          </div>
        </div>

        <div class="finContent">
          <div class="finTitle" style="color: #959494">
            {{ item.group_name }}
          </div>

          <div class="finTotle">總金額：{{ item.total_spend }}</div>
        </div>
      </div>
    </div>

    <v-spinner :spinnerShow="spinnerShow"></v-spinner>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home_finish",
  components: {},
  data() {
    return {
      spinnerShow: false,
      groupFinish: [],
    };
  },
  mounted() {
    this.spinnerShow = true;
    this.getGroupFinish();
  },
  methods: {
    getGroupFinish() {
      this.$store
        .dispatch("group/getGroupFinish")
        .then((response) => {
          console.log(response);
          this.groupFinish = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.spinnerShow = false;
        });
    },
  },
};
</script>
