<template>
    <div class="header">
        <img class="logoimg" src="../../public/images/Logo.png" alt="">

         <!-- <div>
            <button @click="logOut">登出測試</button>
          </div> -->

    </div>
</template>


<script>
export default {
  name: 'Header',
  beforeDestroy(){
    window.liff.logout();    
  },
  methods:{
    logOut: function () {
      window.liff.logout();
    },
  }
}
</script>