<template>
  <div>
    <div class="finBtnList" v-if="!deleteView">
      <div v-if="trueView">
        <button class="activeBtn" @click="sureAct">{{ surebtn[0] }}</button>
        <button class="cancel" @click="sureCancel">{{ surebtn[1] }}</button>
      </div>
    </div>

    <div v-if="deleteView" class="deleteView"></div>
    <div v-if="deleteView" class="deleteBtnList">
      <button class="deleteBtn" @click="deleteAct">
        {{ deletebtn[0] }}
      </button>
      <button class="cancel" @click="deleteCancel">
        {{ deletebtn[1] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["btnList_sure", "btnList_delete", "deleteView", "trueView"],
  name: "buttonList",
  data() {
    return {
      surebtn: this.btnList_sure ? this.btnList_sure : [],
      deletebtn: this.btnList_delete ? this.btnList_delete : [],
    };
  },
  mounted() {},
  methods: {
    sureAct() {
      this.$emit("sureAct");
    },
    sureCancel() {
      this.$emit("sureCancel");
    },
    deleteAct() {
      this.$emit("deleteAct");
    },
    deleteCancel() {
      this.$emit("deleteCancel");
    },
  },
};
</script>