import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 共用文件
import '../public/js/config'
import '../public/css/custom.css'
import axios from './axios/index'
// import VueAxios from 'vue-axios'

// font-awesome掛載
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

//共用組建掛載
import header from './components/Header.vue'
import footer from './components/Footer.vue'
import buttonList from './components/buttonList.vue'
import spinner from './components/spinner.vue'

Vue.prototype.$axios = axios
// Vue.use(VueAxios, axios)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false
Vue.component("v-header",header);
Vue.component("v-footer",footer);
Vue.component("v-buttonList",buttonList);
Vue.component("v-spinner",spinner);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
